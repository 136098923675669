import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTrimInput]',
  standalone: true,
})
export class TrimInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('blur', ['$event.target'])
  onBlur(target: HTMLInputElement | HTMLTextAreaElement): void {
    target.value = target.value.trim();
  }
}
